import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import OtherLayout from '../components/OtherLayout'
import SEO from '../components/SEO'
import Descriptions from "../components/Descriptions"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import logo from '../img/logo.png'

export class PpcThanksPageTemplate extends Component {

  render() {
    const { seo, hero } = this.props
    return (
      <div className="ppc-main-page">
        <SEO title={seo.title} description={seo.description} />
        <div
          style={{
            backgroundImage: `url(${
              !!hero.background.image.childImageSharp ? hero.background.image.childImageSharp.fluid.src : hero.background.image
              })`,
            backgroundPosition: `center center`,
            backgroundSize: 'cover',
            alignItems: "start"
          }}
        >
          <div className="container">
            <Link to="/" title="Logo">
              <img src={logo} alt="Kaldi" style={{ padding: 10, width: 300 }} />
            </Link>
            <div className="columns">
              <div className="column is-6">
                <h1
                  style={{
                    color: 'white',
                    lineHeight: '1',
                    fontWeight: "800",
                    fontSize: "4rem",
                    marginTop: 100
                  }}
                >
                  {hero.title}<br />
                  {hero.secondtitle}
                </h1>
                <div style={{ fontSize: "1.5rem", margin: "2rem 0" }}>
                  <Descriptions descriptions={hero.descriptions} color="white" />
                </div>
                <a href="/" >
                  <button style={{ background: "#8dc643", borderColor: "#8dc643", fontSize: "1.3rem", padding: "10px 2rem" }}>{hero.buttontxt}</button>
                </a>
              </div>
              <div className="column is-6" style={{ marginTop: 50 }}>
                <PreviewCompatibleImage imageInfo={hero.image} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PpcThanksPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
}

const PpcThanksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <OtherLayout>
      <PpcThanksPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
      />
    </OtherLayout>
  )
}

PpcThanksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PpcThanksPage

export const pageQuery = graphql`
  query PpcThanksPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ppc-main-lp-thanks-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title
          secondtitle
          descriptions
          buttontxt
          buttonurl
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          background {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

